export const rate = 5

export const all_products = [
  {
    name: "Tênis Nike - Air Force Premium White",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img2.jpg",
    category: "Nike",
    color: "branco",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Full White",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img22.jpg",
    category: "Nike",
    color: "branco",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Branco Vermelho",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img112.jpg",
    category: "Nike",
    color: "vermelho",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Preto Bordado",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img113.jpg",
    category: "Nike",
    color: "preto",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Preto",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img106.jpg",
    category: "Nike",
    color: "preto",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Black e Red",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img12.jpg",
    category: "Nike",
    color: "preto",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Caramelo Camurça",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img13.jpg",
    category: "Nike",
    color: "marron",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Branco Caramelo",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img27.jpg",
    category: "Nike",
    color: "marron",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Bege Dourado",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img28.jpg",
    category: "Nike",
    color: "bege",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Bege Azul",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img32.jpg",
    category: "Nike",
    color: "bege",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Branco Verde Oliva",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img33.jpg",
    category: "Nike",
    color: "verde",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Branco Azul com Prata",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img29.jpg",
    category: "Nike",
    color: "azul",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Branco Azul Claro com Prata",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img35.jpg",
    category: "Nike",
    color: "azul",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Branco Cinza com Prata",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img34.jpg",
    category: "Nike",
    color: "cinza",
    description:
    "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Branco Cinza",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img39.jpg",
    category: "Nike",
    color: "cinza",
    description:
    "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Branco Nude",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img36.jpg",
    category: "Nike",
    color: "bege",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Branco com Azul",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img30.jpg",
    category: "Nike",
    color: "azul",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Cinza",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img18.jpg",
    category: "Nike",
    color: "cinza",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Off White Preto",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img37.jpg",
    category: "Nike",
    color: "offwhite",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Branco Verde",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img38.jpg",
    category: "Nike",
    color: "verde",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Verde",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img67.jpg",
    category: "Nike",
    color: "verde",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Lilás Amarelo",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img58.jpg",
    category: "Nike",
    color: "colorido",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Moo Colorido",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img66.jpg",
    category: "Nike",
    color: "colorido",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force NBA Lakers",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img40.jpg",
    category: "Nike",
    color: "branco",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Jordan Azul",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img90.jpg",
    category: "Nike",
    color: "azul",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Jordan Aveia",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img48.jpg",
    category: "Nike",
    color: "marron",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Jordan Branco Azul",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img49.jpg",
    category: "Nike",
    color: "branco",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Jordan Branco Jeans",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img50.jpg",
    category: "Nike",
    color: "azul",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Jordan Cinza Amarelo",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img51.jpg",
    category: "Nike",
    color: "cinza",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Dunk White Black",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img52.jpg",
    category: "Nike",
    color: "branco",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Dunk Preto Branco",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img104.jpg",
    category: "Nike",
    color: "preto",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Dunk Cinza Preto",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img55.jpg",
    category: "Nike",
    color: "cinza",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Dunk Azul Rosa",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img54.jpg",
    category: "Nike",
    color: "rosa",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Dunk Marron Café",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img56.jpg",
    category: "Nike",
    color: "marron",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Dunk Chocolate",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img65.jpg",
    category: "Nike",
    color: "marron",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Dunk Marron",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img64.jpg",
    category: "Nike",
    color: "marron",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Dunk Cinza Branco",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img57.jpg",
    category: "Nike",
    color: "cinza",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Dunk Cinza Rosa",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img63.jpg",
    category: "Nike",
    color: "rosa",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Dunk Branco Verde Laranja",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img60.jpg",
    category: "Nike",
    color: "laranja",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Dunk Bege Verde Salmão",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img61.jpg",
    category: "Nike",
    color: "laranja",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Dunk Branco Verde Limão",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img59.jpg",
    category: "Nike",
    color: "verde claro",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Dunk Black",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img62.jpg",
    category: "Nike",
    color: "preto",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Dunk Authentic",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img53.jpg",
    category: "Nike",
    color: "preto",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Nobuck Preto",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img26.jpg",
    category: "Nike",
    color: "preto",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Force Full Black",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img19.jpg",
    category: "Nike",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Max TN Preto",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img109.jpg",
    category: "Nike",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Max TN Branco",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img110.jpg",
    category: "Nike",
    color: "branco",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Max Branco",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img87.jpg",
    category: "Nike",
    color: "branco",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Max Bege Preto",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img86.jpg",
    category: "Nike",
    color: "bege",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Epic Cinza/Rosa",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img11.jpg",
    category: "Nike",
    color: "rosa",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Epic Preto/Rosa",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img14.jpg",
    category: "Nike",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Zoom Pegasus Rosa",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img24.jpg",
    category: "Nike",
    color: "rosa",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Zoom Preto Branco",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img47.jpg",
    category: "Nike",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Zoom Azul Branco",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img102.jpg",
    category: "Nike",
    color: "azul",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Zoom Preto Laranja",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img46.jpg",
    category: "Nike",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Zoom Pegasus Preto",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img25.jpg",
    category: "Nike",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Max 270 Colorido",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img92.jpg",
    category: "Nike",
    color: "colorido",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Max 270 Preto Cinza Branco",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img93.jpg",
    category: "Nike",
    color: "cinza",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Air Max 270 Colorido V2",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img94.jpg",
    category: "Nike",
    color: "colorido",
    description: "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - React Infinity Preto",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img115.jpg",
    category: "Nike",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - React Infinity Preto Rosa",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img25.jpg",
    category: "Nike",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - React Infinity Verde Água",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img41.jpg",
    category: "Nike",
    color: "branco",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - React Infinity Azul Amarelo",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img114.jpg",
    category: "Nike",
    color: "azul",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Rosa",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img42.jpg",
    category: "Nike",
    color: "rosa",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - VaporFly Branco Rosa",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img45.jpg",
    category: "Nike",
    color: "rosa",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Epict Verde Água",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img43.jpg",
    category: "Nike",
    color: "verde",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - VaporFly Preto Azul",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img44.jpg",
    category: "Nike",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Nike - Meia Preto Branco",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img68.jpg",
    category: "Nike",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Louis Vuitton - Trainer Azul",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img100.jpg",
    category: "Louis Vuitton",
    color: "azul",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Louis Vuitton - Trainer Bege",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img101.jpg",
    category: "Louis Vuitton",
    color: "bege",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Louis Vuitton - Trainer Branco Cinza",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img108.jpg",
    category: "Louis Vuitton",
    color: "cinza",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Louis Vuitton - Trainer Preto",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img111.jpg",
    category: "Louis Vuitton",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Louis Vuitton - Skate Verde",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img3.jpg",
    category: "Louis Vuitton",
    color: "verde",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Louis Vuitton - Skate Preto",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img4.jpg",
    category: "Louis Vuitton",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Louis Vuitton - Skate Vermelho",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img5.jpg",
    category: "Louis Vuitton",
    color: "vermelho",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Versat - V Preto Branco",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img77.jpg",
    category: "Versat",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Versat - V Branco com Dourado",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img6.jpg",
    category: "Versat",
    color: "dourado",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Versat - V Rose",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img7.jpg",
    category: "Versat",
    color: "rose",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Versat - V Dourado",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img8.jpg",
    category: "Versat",
    color: "dourado",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Vans - KNU Café/Rosa",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img9.jpg",
    category: "Vans",
    color: "marron",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Vans - KNU Bege",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img71.jpg",
    category: "Vans",
    color: "bege",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Vans - Neo Black White",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img69.jpg",
    category: "Vans",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  }, 
  {
    name: "Tênis Vans - Neo Bege",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img105.jpg",
    category: "Vans",
    color: "bege",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  }, 
  {
    name: "Tênis Vans - Neo Ultrange",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img70.jpg",
    category: "Vans",
    color: "branco",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Vans - Neo Ultrange VR3 Black Marshmallow",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img72.jpg",
    category: "Vans",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Vans - Neo Ultrange VR3 Verde Musgo",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img73.jpg",
    category: "Vans",
    color: "verde",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Vans - Neo Ultrange VR3 Cinza Marshmallow",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img74.jpg",
    category: "Vans",
    color: "cinza",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Vans - Neo Ultrange Black",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img75.jpg",
    category: "Vans",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Vans - Old Skool Black White",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img76.jpg",
    category: "Vans",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },  
  {
    name: "Tênis Vans - Skool Black White",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img10.jpg",
    category: "Vans",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },  
  {
    name: "Tênis All Star - Cano Alto Preto",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img95.jpg",
    category: "All Star",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis All Star - Courino Branco",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img96.jpg",
    category: "All Star",
    color: "branco",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis All Star - Branco",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img97.jpg",
    category: "All Star",
    color: "branco",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis All Star - Courino Preto",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img98.jpg",
    category: "All Star",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis All Star - Vermelho",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img99.jpg",
    category: "All Star",
    color: "vermelho",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Adidas - Campus Black",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img23.jpg",
    category: "Adidas",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },  
  {
    name: "Tênis Adidas - Campus Verde",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img78.jpg",
    category: "Adidas",
    color: "verde",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },  
  {
    name: "Tênis Adidas - Campus Cinza",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img79.jpg",
    category: "Adidas",
    color: "cinza",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Adidas - Campus Cinza Preto Camurça",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img84.jpg",
    category: "Adidas",
    color: "cinza",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Adidas - Campus Azul Ceu",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img80.jpg",
    category: "Adidas",
    color: "azul",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },  
  {
    name: "Tênis Adidas - Campus Cinza Verde",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img81.jpg",
    category: "Adidas",
    color: "verde",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Adidas - Campus Cinza Verde Camurça",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img83.jpg",
    category: "Adidas",
    color: "verde",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Adidas - Campus Marron",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img82.jpg",
    category: "Adidas",
    color: "marron",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },  
  {
    name: "Tênis Adidas - Samba Sintético Black",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img15.jpg",
    category: "Adidas",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },  
  {
    name: "Tênis Adidas - Samba Sintético White",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img16.jpg",
    category: "Adidas",
    color: "branco",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },  
  {
    name: "Tênis Adidas - Samba Sintético Branco Azul",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img17.jpg",
    category: "Adidas",
    color: "azul",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },  
  {
    name: "Tênis Adidas - Super Star Branco Preto",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img91.jpg",
    category: "Adidas",
    color: "branco",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },  
  {
    name: "Tênis Adidas - LXCom Preto",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img85.jpg",
    category: "Adidas",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },  
  {
    name: "Tênis Mizuno - Nirnava Azul",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img20.jpg",
    category: "Mizuno",
    color: "azul",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },  
  {
    name: "Tênis Mizuno - Nirnava Roxo",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img21.jpg",
    category: "Mizuno",
    color: "roxo",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Mizuno - Wave Cinza Laranja",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img116.jpg",
    category: "Mizuno",
    color: "cinza",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Mizuno - Wave Azul",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img107.jpg",
    category: "Mizuno",
    color: "azul",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Oldsen - Unissex Branco Preto",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img88.jpg",
    category: "Oldsen",
    color: "branco",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
  {
    name: "Tênis Reebok - Unissex Preto",
    price: "99.99",
    installmentQtd: "3",
    refID: "3",
    img: "img31.jpg",
    category: "Reebok",
    color: "preto",
    description:
      "Vestibulum eget placerat mauris. Ut quis commodo tortor, a iaculis metus.",
  },
];

export const colors = [
  {
    colorName: "rosa",
    bg: "#FE96C2",
    hex: "#570B2B",
  },
  {
    colorName: "verde",
    bg: "#7FD878",
    hex: "#245320",
  },
  {
    colorName: "verde claro",
    bg: "#a1f10b",
    hex: "#245320",
  },
  {
    colorName: "laranja",
    bg: "#F4B770",
    hex: "#563309",
  },
  {
    colorName: "marron",
    bg: "#ac8872",
    hex: "#5C2E2E",
  },
  {
    colorName: "bege", 
    bg: "#F5F5DC",
    hex: "#D2B48C",
  },
  {
    colorName: "offwhite", 
    bg: "#FAF9F6",
    hex: "#EAEAEA",
  },
  {
    colorName: "azul",
    bg: "#96C0FE",
    hex: "#1F3556",
  },
  {
    colorName: "vermelho",
    bg: "#F56F6B",
    hex: "#520F0F",
  },
  {
    colorName: "roxo",
    bg: "#e0d1ff",
    hex: "#2A1B4A",
  },
  {
    colorName: "amarelo",
    bg: "#F1F371",
    hex: "#4A4B0A",
  },
  {
    colorName: "dourado", 
    bg: "#ecdda3", 
    hex: "#B8860B", 
  },
  {
    colorName: "rose",
    bg: "#FFC0CB",
    hex: "#FF007F",
  },
  {
    colorName: "preto",
    bg: "#5C5C5C",
    color: "#FFFFFF",
  },
  {
    colorName: "cinza", 
    bg: "#D3D3D3", 
    hex: "#A9A9A9",
  },
  {
    colorName: "branco",
    bg: "#FFFFFF",
    color: "#2C2C2C",
  },
  {
    colorName: "colorido",
    bg: "linear-gradient(90deg, rgba(255,0,0,1) 0%, rgba(255,255,0,1) 20%, rgba(0,255,196,1) 50%, rgba(109,0,255,1) 80%, rgba(255,0,0,1) 100%)",
    color: "#2C2C2C",
  },
];