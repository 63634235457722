import React, { useCallback, useContext, useRef, useState } from "react";
import { AlertContext } from "../../../context/AlertContext";
import { CartContext } from "../../../context/CartContext";
import { ReactComponent as IconCart } from "../../../assets/cart.svg";
import { ReactComponent as LogoSvg } from "../../../assets/logo.svg";
import classes from "./ProductItem.module.css";
import { rate } from "../../../data";
import QuickView from "../../quickView/QuickView";

const getProductImage = ({ img, name, showFinalModalHandler, quickViewModal }) => {
  try {
    return (
      <>
        <img src={require(`../../../assets/imgs-produtos/${img}`)} alt={name} onClick={showFinalModalHandler} />
        {quickViewModal && <QuickView name={name} img={img} onClose={showFinalModalHandler} />}
      </>
    );
  } catch (error) {
    return <LogoSvg />;
  }
};

const ProdutoItem = ({ name, img, price, installmentQtd, description }) => {
  const [amount, setAmount] = useState(1);
  const [quickViewModal, setQuickViewModal] = useState(false);
  const animaTimeout = useRef();

  const { addItem } = useContext(CartContext);
  const { showAlert } = useContext(AlertContext);

  const addItemToCartHandler = useCallback((e) => {
    e.preventDefault();

    clearTimeout(animaTimeout.current);

    addItem({
      name,
      price,
      installmentQtd,
      amount: +amount,
      img,
    });

    showAlert(`${amount} "${name}" adicionado(s) ao carrinho`);
    setAmount(1);
  }, [amount, name, price, installmentQtd, img, addItem, showAlert]);

  const showFinalModalHandler = () => {
    setQuickViewModal(!quickViewModal);
  };

  // Funções para alterar a quantidade
  const incrementAmount = () => setAmount((prev) => prev + 1);
  const decrementAmount = () => setAmount((prev) => Math.max(prev - 1, 1));

  return (
    <li className={classes.card}>
      <div className={classes.imgContainer}>
        {getProductImage({ img, name, showFinalModalHandler, quickViewModal })}
      </div>
      <div className={classes.infos}>
        <div>
          <p className={classes.name}>{name}</p>
          <p className={classes.description} title={description}>
            {description}
          </p>
        </div>

        <div className={classes.flex}>
          <div className={classes.block}>
            {installmentQtd && <p className={classes.installmentQtd}>{installmentQtd} x de R$ {((Number(price) + rate) / installmentQtd).toFixed(2)}</p>}
            <p className={classes.price}>R$ {price}</p>
          </div>
          <form className={classes.amountForm}>
            <button
              className={classes.btnAddRemove}
              onClick={(e) => { e.preventDefault(); decrementAmount(); }}
              title="adicionar produto"
            >
              -
            </button>
            <label htmlFor={`amount-${name}`}>
              <span>Quantidade</span>
              <input
                type="number"
                name="quantidade"
                id={`amount-${name}`}
                value={amount}
                onChange={(e) => setAmount(Math.max(Number(e.target.value), 1))}
                step="1"
                min="1"
              />
            </label>
            <button
              className={classes.btnAddRemove}
              onClick={(e) => { e.preventDefault(); incrementAmount() }}
              title="Remover carrinho"
            >
              +
            </button>
          </form>
        </div>
        <button
          className={`${classes.btnAddToCart} ${classes.btn}`}
          onClick={addItemToCartHandler}
          title="adicionar ao carrinho"
        >
          <span className={classes.value}>Adicionar</span>
          <IconCart />
        </button>
      </div>
    </li>
  );
};

export default ProdutoItem;
